<template>
  <vue-good-table
    :columns="columns"
    :rows="rows"
    :pagination-options="{
      enabled: true,
      mode: 'records',
      perPage: per_page
    }"
    :sort-options="{
      enabled: true,
      initialSortBy: {field: 'date', type: 'asc'}
    }"
    :groupOptions="{
      enabled: true
    }"
    @on-row-click="onRowClick"
    styleClass="vgt-table bordered condensed striped"
    >
    <template slot="table-row" slot-scope="props">
      <span v-if="props.column.field == 'items'">
        <span style="white-space: pre;">{{itemsSold(props.row.items)}}</span>
      </span>
      <span v-else>
        {{props.formattedRow[props.column.field]}}
      </span>
    </template>
  </vue-good-table>
</template>

<script>
import moment from 'moment-timezone'

export default {
  name: 'meal-and-attractions-table',
  props: ['meal_and_attraction_vendors', 'per_page'],
  data() {
    return {
      columns: [
        {
          label: 'Group',
          field: 'name',
        },
        {
          label: 'Date',
          field: 'date',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd',
          dateOutputFormat: 'M/d/yyyy'
        },
        {
          label: 'Items Sold',
          field: 'items',
          // formatFn: this.itemsSold,
        },
        // {
        //   label: 'Total in Group',
        //   field: 'items',
        //   formatFn: this.totalInGroup
        // }
      ],
      rows: [
        { id: 1, name: 'Test', date: '2020-02-02', items: '' }
      ]
    }
  },
  created () {
    var parsedVendors = this.meal_and_attraction_vendors.map(obj => {
      var rObj = {};
      rObj['mode'] = 'span';
      rObj['label'] = obj.name;
      rObj['html'] = false;
      rObj['children'] = obj.groups;
      return rObj;
    });

    this.rows = parsedVendors;
  },
  methods: {
    datesRange(value) {
      if (value.length > 1) {
        return moment(value[0]['date']).format('M/D/YYYY');
      } else {
        return moment(value.map(v => { return v['date'] }).join('')).format('M/D/YYYY');
      }
    },
    itemsSold(value) {
      var items = value.map(v => { return v['item_name_proposal'] }).sort();
      var new_items = new Set(items);

      return [...new_items].join("\n");
      // return value.map(v => { return v['item_name_proposal'] }).sort().join("\n");
    },
    onRowClick(params) {
      window.location = '/proposals/' + params.row.proposal_id;
    },
    totalInGroup(value) {
      return value[0]['total_quantity'];
    }
  }
}
</script>

<style>
</style>
