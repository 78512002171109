<template>
  <div>
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :pagination-options="{
        enabled: true,
        mode: 'records',
        perPage: per_page
      }"
      :groupOptions="{
        enabled: true
      }"
      @on-row-click="onRowClick"
      styleClass="vgt-table bordered condensed striped"
      >
    </vue-good-table>
  </div>
</template>

<script>
export default {
  name: 'coach-contracts-table',
  props: ['coach_vendors', 'per_page'],
  data() {
    return {
      columns: [
        {
          label: 'Group',
          field: 'group_name',
        },
        {
          label: 'Destination',
          field: 'destination',
        },
        {
          label: 'Departure Date',
          field: 'departure_date',
          type: 'date',
          dateInputFormat: 'yyyy-mm-dd',
          dateOutputFormat: 'm/d/yyyy'
        },
        {
          label: 'Coaches',
          field: 'units',
          type: 'number'
        }
      ],
      rows: null
    }
  },
  created () {
    var parsedVendors = this.coach_vendors.map(obj => {
      var rObj = {};
      rObj['mode'] = 'span';
      rObj['label'] = obj.name;
      rObj['html'] = false;
      rObj['children'] = obj.coach_contracts;
      return rObj;
    });

    this.rows = parsedVendors;
  },
  methods: {
    onRowClick(params) {
      window.location = '/coach_contracts/' + params.row.id;
    }
  }
}
</script>
