// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// require("@rails/ujs").start()
// require("turbolinks").start()
// require("@rails/activestorage").start()
// require("channels")

import 'styles/app-styles'

import 'babel-polyfill'
import Rails from 'rails-ujs'
import Turbolinks from 'turbolinks'
import TurbolinksAdapter from 'vue-turbolinks'
import Vue from 'vue/dist/vue.esm'
// import VeeValidate from 'vee-validate'
import Buefy from 'buefy'
import moment from 'moment-timezone'
import VueGoodTablePlugin from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'

import { Firebase, initFirebase } from './firebase'

import AddAdmin from '../components/add_admin.vue'
import CoachContractsTable from '../components/coach_contracts_table.vue'
import CompanyContactCard from '../components/company_contact_card.vue'
import DownloadButton from '../components/download_button.vue'
import FirebaseLogin from '../components/firebase_login.vue'
import ItemsByDateTable from '../components/items_by_date_table.vue'
import LodgingContractsTable from '../components/lodging_contracts_table.vue'
import MealAndAttractionsTable from '../components/meal_and_attractions_table.vue'
import PerformanceScheduleTable from '../components/performance_schedule_table.vue'
import PerformanceSitesTable from '../components/performance_sites_table.vue'
import TripMealAndAttractionsTable from '../components/trip_meal_and_attractions_table.vue'

Rails.start()
Turbolinks.start()

Vue.use(Buefy, {
  defaultIconPack: 'fa'
})
Vue.use(VueGoodTablePlugin)
Vue.use(TurbolinksAdapter)

Vue.component('add-admin', AddAdmin)
Vue.component('coach-contracts-table', CoachContractsTable)
Vue.component('company-contact-card', CompanyContactCard)
Vue.component('download-button', DownloadButton)
Vue.component('firebase-login', FirebaseLogin)
Vue.component('items-by-date-table', ItemsByDateTable)
Vue.component('lodging-contracts-table', LodgingContractsTable)
Vue.component('meal-and-attractions-table', MealAndAttractionsTable)
Vue.component('performance-schedule-table', PerformanceScheduleTable)
Vue.component('performance-sites-table', PerformanceSitesTable)
Vue.component('trip-meal-and-attractions-table', TripMealAndAttractionsTable)

document.addEventListener('turbolinks:load', () => {
  const app = new Vue ({
    el: '[data-behavior="vue"]',
    data: {
      shownav: false
    }
  })
})
