<template>
  <div id="firebaseui-auth-container"></div>
</template>

<script>
import { Firebase } from '../packs/firebase'
import '../../../node_modules/firebaseui/dist/firebaseui.css'

import * as firebaseui from 'firebaseui'

export default {
  data () {
    return {

    }
  },
  mounted() {
    var uiConfig = {
      callbacks: {
        signInSuccessWithAuthResult: function(authResult) {
          Firebase.auth().currentUser.getIdTokenResult()
            .then(result => {
              window.location.assign('sessions/create?idToken=' + result.token);
            });
        }
      },
      signInFlow: 'popup',
      signInOptions: [
        {
          provider: Firebase.auth.EmailAuthProvider.PROVIDER_ID
        },
        {
          provider: Firebase.auth.GoogleAuthProvider.PROVIDER_ID
        },
        {
          provider: 'microsoft.com',
          providerName: 'Microsoft',
          buttonColor: '#2F2F2F',
          iconUrl: 'https://www.gstatic.com/mobilesdk/170404_mobilesdk/auth_service_mslive.svg',
          loginHintKey: 'login_hint',
          scopes: [
            'mail.read'
          ],
          customParameters: {
            prompt: 'consent'
          }
        }
      ],
    };

    var ui = new firebaseui.auth.AuthUI(Firebase.auth());
    ui.start('#firebaseui-auth-container', uiConfig);
  }
}
</script>
