<template>
  <vue-good-table
    :columns="columns"
    :rows="rows"
    :pagination-options="{
      enabled: true,
      mode: 'records',
      perPage: 20
    }"
    :sort-options="{
      enabled: true,
      initialSortBy: [
        {field: 'date', type: 'asc'},
        {field: 'time', type: 'asc'}
      ],
    }"
    styleClass="vgt-table bordered condensed striped"
  >
    <template slot="table-row" slot-scope="props">
      <span v-if="props.column.field == 'total_quantity'">
        <span>{{ props.row.adult_quantity + props.row.student_quantity }}</span>
      </span>
      <span>
        {{props.formattedRow[props.column.field]}}
      </span>
    </template>
  </vue-good-table>
</template>

<script>
  import moment from 'moment-timezone'
  
  export default {
    name: 'trip-meal-and-attractions',
    props: ['proposal_items'],
    data () {
      return {
        columns: [
          {
            label: 'Item',
            field: 'item_name_proposal'
          },
          {
            label: 'Date',
            field: 'date',
            type: 'date',
            dateInputFormat: 'yyyy-MM-dd',
            dateOutputFormat: 'M/d/yyyy'
          },
          {
            label: 'Time',
            field: 'time',
            formatFn: this.formatTime,
          },
          {
            label: 'Total Quantity',
            field: 'total_quantity'
          },
          {
            label: 'Student Quantity',
            field: 'student_quantity'
          },
          {
            label: 'Adult Quantity',
            field: 'adult_quantity'
          },
          {
            label: 'Confirmation Number',
            field: 'confirmation_number'
          },
        ],
        rows: [
          { id: 1, item: "Test", date: '2020-02-01', time: '10:50 AM', total_quantity: 55, student_quantity: 50, adult_quantity: 5, confirmation_number: '123456' }
        ] }
    },
    created () {
      var parsedProposalItems = this.proposal_items.map(obj => {
        var rObj = {};
        rObj = obj.table;
        return rObj;
      });

      this.rows = parsedProposalItems;
    },
    methods: {
      formatTime(value) {

        return moment(value, moment.HTML5_FMT.TIME_MS).format('h:mm A');
      }
    },
  }
</script>
