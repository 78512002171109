<template>
  <div>
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :pagination-options="{
        enabled: true,
        mode: 'records',
        perPage: per_page,
      }"
      :sort-options="{
        enabled: true,
        initialSortBy: {field: 'start_date'}
      }"
      :groupOptions="{
        enabled: true
      }"
      @on-row-click="onRowClick"
      styleClass="vgt-table bordered condensed striped"
      >
      <template slot="loadingContent">
      </template>
    </vue-good-table>
  </div>
</template>

<script>
export default {
  name: 'lodging-contracts-table',
  props: ['lodging_vendors', 'per_page'],
  data() {
    return {
      columns: [
        {
          label: 'Property',
          field: 'property_name'
        },
        {
          label: 'Date',
          field: 'start_date',
          type: 'date',
          dateInputFormat: 'yyyy-mm-dd',
          dateOutputFormat: 'm/d/yyyy',
          sortable: true,
          sortFn: this.sortFn
        },
        {
          label: 'Contracted',
          field: 'contracted',
          type: 'number'
        },
        {
          label: 'Sold',
          field: 'utilized',
          type: 'number'
        },
      ],
      rows: null
    }
  },
  created () {
    console.log(this.lodging_vendors);

    var parsedVendors = this.lodging_vendors.map(obj => {
      var rObj = {};
      rObj['mode'] = 'span';
      rObj['label'] = obj.name;
      rObj['html'] = false;
      rObj['children'] = obj.lodging_contracts;
      return rObj;
    });

    this.rows = parsedVendors;
  },
  methods: {
    onRowClick(params) {
      window.location = '/lodging_contracts/' + params.row.id;
    },
    sortFn(x, y, col, rowX, rowY) {
      return (x < y ? -1 : (x > y ? 1 : 0));
    }
  }
}
</script>
