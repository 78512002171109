import * as Firebase from 'firebase/app'

import 'firebase/auth'
import 'firebase/functions'

var config = {
    apiKey: "AIzaSyCXrmwqcMyLllECh6L7JbG8GBQo_o3rd7w",
    authDomain: "choice-vendors.firebaseapp.com",
    databaseURL: "https://choice-vendors.firebaseio.com",
    projectId: "choice-vendors",
    storageBucket: "choice-vendors.appspot.com",
    messagingSenderId: "844667470486",
    appId: "1:844667470486:web:551ff1ee6fe198774db8b7",
    measurementId: "G-HP7EPJ2JJR"
  }

Firebase.initializeApp(config)

export { Firebase }
