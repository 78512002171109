<template>
  <b-button tag="a"
    :id="id"
    type="is-light"
    :href="link"
    target="_blank"
    icon-left="fas fa-download">
    {{ this.text }}
  </b-button>
</template>

<script>
export default {
  name: 'download-button',
  props: ['id', 'text', 'link']
}
</script>

