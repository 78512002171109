<template>
  <vue-good-table
    :columns="columns"
    :rows="rows"
    :groupOptions="{
      enabled: true
    }"
    styleClass="vgt-table bordered condensed striped"
  >
  </vue-good-table>
</template>

<script>
import moment from 'moment-timezone'

export default {
  name: 'items-by-date-table',
  props: ['items_by_date'],
  data() {
    return {
      columns: [
        {
          label: 'Group',
          name: 'group_name'
        }
      ]
    }
  },
  created () {
    var parsedItems = this.items_by_date.map(obj => {
      var rObj = {};
      rObj['mode'] = 'span';
      rObj['label'] = obj.name;
      rObj['html'] = false;
      rObj['children'] = obj.meals_and_attractions;
      return rObj;
    });

    console.log(parsedItems);

    this.rows = parsedItems;
  }
}
</script>
