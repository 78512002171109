var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"pagination-options":{
      enabled: true,
      mode: 'records',
      perPage: _vm.per_page,
    },"sort-options":{
      enabled: true,
      initialSortBy: {field: 'start_date'}
    },"groupOptions":{
      enabled: true
    },"styleClass":"vgt-table bordered condensed striped"},on:{"on-row-click":_vm.onRowClick}},[_c('template',{slot:"loadingContent"})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }