var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"pagination-options":{
    enabled: true,
    mode: 'records',
    perPage: 20
  },"sort-options":{
    enabled: true,
    initialSortBy: [
      {field: 'date', type: 'asc'},
      {field: 'time', type: 'asc'}
    ],
  },"styleClass":"vgt-table bordered condensed striped"},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'total_quantity')?_c('span',[_c('span',[_vm._v(_vm._s(props.row.adult_quantity + props.row.student_quantity))])]):_vm._e(),_vm._v(" "),_c('span',[_vm._v("\n      "+_vm._s(props.formattedRow[props.column.field])+"\n    ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }