<template>
  <vue-good-table
    :columns="columns"
    :rows="rows"
    @on-row-click="onRowClick"
    styleClass="vgt-table bordered condensed striped">
  </vue-good-table>
</template>

<script>
import moment from 'moment';

export default {
  name: 'performance-sites-table',
  props: ['performance_sites', 'per_page'],
  data () {
    return {
      columns: [
        {
          label: 'Date',
          field: this.dateRange,
          // type: 'date',
          // dateInputFormat: 'yyyy-MM-dd',
          // dateOutputFormat: 'M/d/yyyy'
        },
        {
          label: 'Site Name',
          field: 'site_name'
        },
      ],
      rows: null
    }
  },
  created () {
    this.rows = this.performance_sites;
  },
  methods: {
    dateRange(rowObj) {
      const start = moment(rowObj.start_date).format('M/D/YYYY');
      const end = moment(rowObj.end_date).format('M/D/YYYY');

      if (start == end) {
        var range = start;
      } else {
        var range = moment(rowObj.start_date).format('M/D') + ' - ' + moment(rowObj.end_date).format('M/D/YYYY');
      }

      return range;
    },
    onRowClick(params) {
      window.location = '/performances/' + params.row.id;
    },
  }
}
</script>
