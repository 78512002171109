var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"pagination-options":{
    enabled: true,
    mode: 'records',
    perPage: _vm.per_page
  },"sort-options":{
    enabled: true,
    initialSortBy: {field: 'date', type: 'asc'}
  },"groupOptions":{
    enabled: true
  },"styleClass":"vgt-table bordered condensed striped"},on:{"on-row-click":_vm.onRowClick},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'items')?_c('span',[_c('span',{staticStyle:{"white-space":"pre"}},[_vm._v(_vm._s(_vm.itemsSold(props.row.items)))])]):_c('span',[_vm._v("\n      "+_vm._s(props.formattedRow[props.column.field])+"\n    ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }