<template>
  <section>
    <b-button @click="addAdmin">Set Admin</b-button>
    <b-button @click="addCoordinator">Set Travel Coordinator</b-button>
  </section>
</template>

<script>
import { Firebase } from '../packs/firebase'

export default {
  name: 'add-admin',
  props: ['email'],
  created () {
  },
  methods: {
    addAdmin(params) {
      var addAdmin = Firebase.functions().httpsCallable('addAdmin');

      addAdmin({email: this.email}).then((result) => {
        console.log(result);
      });
    },
    addCoordinator(params) {
      var addTravelCoordinator = Firebase.functions().httpsCallable('addTravelCoordinator');

      addTravelCoordinator({email: this.email}).then((result) => {
        console.log(result);
      });
    }
  }
}
</script>
