<template>
  <vue-good-table
    :columns="columns"
    :rows="rows"
    :groupOptions="{
      enabled: true
    }"
    :sort-options="{
      enabled: false
    }"
    styleClass="vgt-table bordered condensed striped">
    <template slot="table-row" slot-scope="props">
      <span v-if="props.column.field == 'group_name'">
        <a :href="proposal_link(props.row.proposal_id)">{{props.row.group_name}}</a>
      </span>
      <span v-else>
        {{props.formattedRow[props.column.field]}}
      </span>
    </template>
  </vue-good-table>
</template>

<script>
import moment from 'moment-timezone'

export default {
  name: 'performance-schedule-table',
  props: ['performance_schedule'],
  data () {
    return {
      columns: [
        {
          label: 'Start Time',
          field: 'time_start',
          formatFn: this.formatTime,
        },
        {
          label: 'Group',
          field: 'group_name'
        },
        {
          label: 'Ensemble',
          field: 'ensemble_category'
        },
        {
          label: 'Ensemble Size',
          field: 'ensemble_size'
        }
      ],
      rows: null
    }
  },
  created () {
    let schedule = this.performance_schedule.reduce((r, a) => {
      r[a.date] = [...r[a.date] || [], a];
      return r;
    }, {});
    
    console.log(typeof schedule);

    var parsedSchedule = Object.entries(schedule).map(obj => {
      console.log(obj);
      var rObj = {};
      rObj['mode'] = 'span';
      rObj['label'] = moment(obj[0]).format('M/D/YYYY');
      rObj['html'] = false;
      rObj['children'] = obj[1];
      return rObj;
    });

    this.rows = parsedSchedule;
    // this.rows = this.performance_schedule;
  },
  methods: {
    formatTime(value) {
      return moment(value, moment.HTML5_FMT.TIME_MS).format('h:mm A');
    },
    proposal_link(proposal_id) {
      return '/proposals/' + proposal_id;
    }
  }
}
</script>
