<template>
  <div class="card">
    <header class="card-header">
      <p class="card-header-title">Director's Choice Contact</p>
    </header>
    <div class="card-content">
      <table class="table is-fullwidth is-narrow no-row-borders">
        <tbody>
          <tr>
            <th>{{ company_contact.table.type }}:</th>
            <td>{{ company_contact.table.first_name }} {{ company_contact.table.last_name }}</td>
          </tr>
          <tr>
            <th>Phone Number:</th>
            <td>{{ company_contact.table.phone_number }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'company-contact-card',
  props: ['company_contact'],
  created() {
  }
}
</script>
